import styled from 'styled-components';

const DesktopMenuWrapper = styled.div`
  #desktopmenu .flexboxprops {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  #desktopmenu .flexalignstart {
    align-items: flex-start;
  }

  #desktopmenu ul a {
    display: block;
  }

  #desktopmenu .customItem {
    padding: 0 15px;
    margin-bottom: 2px;
  }

  #desktopmenu ul a,
  #desktopmenu ul span {
    padding: 10px 20px;
    color: #fff;
  }
  #desktopmenu ul .spacingmenu {
    padding: 10px 20px;
  }

  #desktopmenu .dropdownMenu {
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    /* max-width: 1170px; */
    max-width: 917px;
    margin: 0 auto;
    /* background-color: rgba(255, 255, 255, 0.95); */
    background-color: #fff;
    border-top: 2px solid #f2863a;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 10px 60px;
  }

  #desktopmenu .dropdownMenu * {
    color: #212121;
  }

  #desktopmenu .dropdownMenu1,
  #desktopmenu .dropdownMenu3,
  #desktopmenu .dropdownMenu4,
  #desktopmenu .dropdownMenu5,
  #desktopmenu .dropdownMenu2 {
    /* display: none; */
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease-out;
  }

  #desktopmenu .dropdownMenu3 {
    left: 20%;
    width: 100%;
    max-width: 415px;
  }
  #desktopmenu .dropdownMenu4 {
    left: 0;
    right: 0;
    width: 100%;
    min-width: 200px;
    max-width: 200px;
  }
  #desktopmenu .dropdownMenu5 {
    left: 0;
    right: 0;
    width: 100%;
    min-width: 375px;
    max-width: 375px;
    display: block !important;
  }
  #desktopmenu .dropdownMenu4 ul,
  #desktopmenu .dropdownMenu5 ul {
    width: 100%;
  }
  #desktopmenu .dropdownMenu4 li,
  #desktopmenu .dropdownMenu5 li {
    padding-left: 10px;
  }

  #desktopmenu .flexcolumnprops {
    flex-direction: column;
  }
  #desktopmenu .flexjustifystartprops {
    justify-content: flex-start;
  }

  #desktopmenu .dropdownMenu2 span {
    text-align: center;
    padding: 5px 2px;
  }
  #desktopmenu .text_size12 {
    font-size: 12px;
  }

  #desktopmenu .text_bold {
    font-weight: bold;
  }

  #desktopmenu i {
    font-size: 12px;
    margin-left: 5px;
    margin-bottom: 8px;
    vertical-align: middle;
  }

  #desktopmenu .mainmenuhead,
  #desktopmenu .mainmenuhead2 {
    display: inline-block;
    padding: 35px 10px;
    cursor: pointer;
  }
  #desktopmenu .mainmenuhead.mainmenuheadAbout,
  #desktopmenu .mainmenuhead.menu_blog {
    cursor: default;
  }

  #desktopmenu .mainmenuhead:hover nav {
    display: flex;
    opacity: 1;
    visibility: visible;
  }
  #desktopmenu .mainmenuhead2:hover nav {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  #desktopmenu .dropdownMenu1 ul.subwidth30 {
    width: 40%;
    margin-top: 15px;
    margin-bottom: -15px;
  }
  #desktopmenu .dropdownMenu1 ul.subwidth70 {
    width: 60%;
    margin-top: -15px;
    margin-bottom: 15px;
  }

  #desktopmenu .dropdownMenu1 .subwidth30 li {
    position: relative;
  }
  #desktopmenu .dropdownMenu1 .subwidth30 li:first-child i {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  #desktopmenu .dropdownMenu2 li {
    width: 33%;
  }
  #desktopmenu .dropdownMenu2 .five_box li {
    width: 20%;
  }

  #desktopmenu .btn.customItem {
    display: inline-block;
    vertical-align: middle;
    margin: 20px;
    margin-bottom: 32px;
    padding: 10px;
  }

  #desktopmenu .btn.btn_tertiary {
    height: 38px;
    min-width: 90px;
    max-width: 120px;
  }

  #desktopmenu .mainmenuhead2 img,
  #desktopmenu .mainmenuhead img {
    width: 60px;
    height: 60px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  #desktopmenu .dropdownMenu3 {
    align-items: flex-start;
  }

  #desktopmenu .mainmenuhead:hover > span,
  #desktopmenu .mainmenuhead2:hover > span,
  #desktopmenu .mainmenuheadAbout a:hover,
  #desktopmenu .mainmenuhead .dropdownMenu3 ul > li > a:hover,
  #desktopmenu .mainmenuhead .dropdownMenu4 ul > li > a:hover,
  #desktopmenu .mainmenuhead .dropdownMenu5 ul > li > a:hover,
  #desktopmenu .mainmenuhead2 .dropdownMenu2 ul > li > a:hover span {
    color: #fc5546;
  }
  #desktopmenu .mainmenuhead .dropdownMenu3 ul {
    width: 50%;
  }

  /* #desktopmenu .mainmenuhead .dropdownMenu5 ul > li:hover, */
  #desktopmenu .mainmenuhead .dropdownMenu4 ul > li:hover,
  #desktopmenu .mainmenuhead .dropdownMenu3 ul > li:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
  #desktopmenu .mainmenuhead .dropdownMenu3 .spacingmenu:hover {
    background-color: transparent;
    cursor: default;
  }

  #desktopmenu .mainmenuhead .dropdownMenu5 ul > li:hover:first-child,
  #desktopmenu .mainmenuhead .dropdownMenu4 ul > li:hover:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  /* getting started button */
  #desktopmenu .gtnStarted:hover {
    background-color: transparent !important;
  }
  #desktopmenu .gtnStarted {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  #desktopmenu .buildYourOwn a {
    padding: 10px 70px;
  }
  #desktopmenu .buildYourOwn {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #f5f5f5;
  }
  #desktopmenu .gtnStarted .btn-text {
    color: #fff;
  }
  #desktopmenu .dropdownMenu5 li {
    padding-left: 0;
    width: 50%;
  }
  #desktopmenu .dropdownMenu5 .text_bold {
    padding-bottom: 0;
  }
  #desktopmenu .dropdownMenu5 ul {
    display: flex;
  }
  #desktopmenu .dropdownMenu5 a:hover span.text {
    color: #fc5546;
  }
  #desktopmenu .dropdownMenu5 span.text {
    padding: 10px 0;
    padding-top: 0;
  }

  /* icon menu */

  .mainmenuhead.menu_blog {
    margin-right: 15px;
  }
  .mainmenuhead.menu_blog a:hover {
    color: #fc5546 !important;
  }

  @media only screen and (max-width: 991px) {
    #desktopmenu {
      display: none;
    }
  }

  /* @media only screen and (min-width: 320px) and (max-height: 625px) {
    #desktopmenu {
      display: none;
    }
    .reusecore-drawer__handler {
      display: inline-block !important;
    }
  } */
`;
export default DesktopMenuWrapper;
