import styled from 'styled-components';

const RibbonWrapper = styled.section`
  // background-color: #191c3c;
  background-image: linear-gradient(
    180deg,
    rgba(25, 28, 60, 0.95) 10%,
    rgba(25, 28, 60, 1)
  );
  .splitter {
    border-right: 1px solid #8f90a0;
    height: 20px;
    width: 1px;
    margin-right: 15px;
  }
  .ribbonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 0;
    padding-right: 0;
    a {
      color: rgba(255, 255, 255, 0.5);
      &:hover {
        color: #f25663;
      }
    }
    > a {
      margin-right: 15px;
    }
    .socialRibbon {
      display: flex;
      align-items: center;
      i {
        font-size: 18px;
      }
      a {
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          i {
            color: #f25663;
          }
        }
      }
    }
  }
`;

export default RibbonWrapper;
