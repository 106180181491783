import styled from 'styled-components';

const DesktopMenuWrapper = styled.div`
  // ----------------------------------Common codes--------------------------------------
  a:link,
  a:visited,
  a:active {
    color: #000000;
  }
  a:hover {
    color: red;
  }
  i {
    margin-left: 5px;
    font-size: 10px;
  }
  img {
    width: 75px;
    height: 75px;
  }
  .desktop_dropdown_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dropdownmenu {
    border-radius: 8px;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    position: absolute;
    background-color: #eeeeee;
    top: 100%;
    display: none;
  }
  .dropdownmenu_toggler {
    padding: 10px;
  }
  .navitems:hover .dropdownmenu {
    display: block;
  }
  .dropdownmenu_toggler:hover {
    cursor: pointer;
    color: red;
  }
  .dropdownmenu_toggler:hover .submenu {
    visibility: visible;
    background-color: #ffff;
  }
  .navitems {
    padding: 35px 20px;
  }
  .navitems:hover > span {
    color: red;
    cursor: pointer;
  }
  .submenu {
    padding: 10px;
    position: absolute;
    left: 30%;
    right: 0;
    top: 0;
    visibility: hidden;
    height: 100%;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
  }
  .visiblemenu {
    visibility: visible;
    background-color: #ffff;
  }
  // ------------------------------------------Styler codes-------------------------------------
  .display_flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .centered_text {
    text-align: center;
  }
  .padded_lists {
    li {
      width: 100%;
      padding-bottom: 10px;
      a {
        display: block;
        width: 100%;
      }
    }
  }
  .smallfont {
    font-size: 14px;
    margin-top: 20px;
  }
  .pad_left_favi {
    margin-left: 8px;
  }
  .left_margin_button {
    margin-left: 50%;
    transform: translateX(-20%);
  }
  //   ------------------------------------ Menu based codes----------------------------------

  .platform_menu_size {
    width: 350px;
    height: 200px;
  }
  .solutionfocus_menu_size {
    width: 420px;
    height: fit-content;
    // transform: translateX(-30%);
  }
  .usecasess_menu_size {
    width: 350px;
    height: 250px;
  }
  .resources_menu_size {
    width: fit-content;
    height: fit-content;
    margin-right: 80px;
  }
  @media only screen and (max-width: 991px) {
    .desktop_dropdown_menu {
      display: none;
    }
  }
`;
export default DesktopMenuWrapper;
