import React from 'react';
import Container from 'common/src/components/UI/Container';
import { Link } from 'gatsby';
import RibbonWrapper from './ribbon.style';

export default function TopRibbon() {
  return (
    <RibbonWrapper>
      <Container>
        <div className="ribbonContainer">
          <Link to="/contact">Contact Us</Link>
          <Link to="/careers">Careers</Link>
          <div className="splitter"></div>
          <div className="socialRibbon">
            <a href="https://www.facebook.com/rapventures/">
              <i className="fa fa-facebook" />
            </a>
            <a href="https://twitter.com/rap_ventures">
              <i className="fa fa-twitter" />
            </a>
            <a href="https://www.linkedin.com/company/rapid-acceleration-partners">
              <i className="fa fa-linkedin" />
            </a>
            <a href="https://www.instagram.com/rapid_acceleration_partners/">
              <i className="fa fa-instagram" />
            </a>
            <a href="https://www.youtube.com/channel/UCMSXhWJFm3Vvp1Rmbfa54lw">
              <i className="fa fa-youtube-square" />
            </a>
          </div>
        </div>
      </Container>
    </RibbonWrapper>
  );
}
