import React from 'react';
import { Link } from 'gatsby';

import './mobilemenu.css';
import { render } from 'react-dom';

// import leftarrow from 'common/src/assets/image/hosting/Symbols/arrow_left.png';

class MainMobilemenu1 extends React.Component {
  componentDidMount() {
    var dropMenus = document.querySelectorAll('.mobile_menu_drop');
    function addActive() {
      for (let i = 0; i < dropMenus.length; i++) {
        dropMenus[i].addEventListener('click', function() {
          this.parentElement.classList.toggle('active');
        });
      }
      // end of add class
      // setTimeout(() => {
      //   document.querySelector('.reusecore-drawer__close').addEventListener('click', function() {
      //     var activeClass = document.querySelectorAll('.mobile_menu_drop');
      //     for (let i = 0; i < activeClass.length; i++) {
      //       if(activeClass[i].classList.contains('active')) {
      //         let x = activeClass[i].className;
      //         x.replace(/active/g, "");
      //       }
      //     }
      //   });
      // }, 2500);
    }
    addActive();
  }
  render() {
    return (
      <>
        <div id="Mobile_menu_container">
          <ul className="scrollspy__menu mobile_menu">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <span className="mobile_menu_drop">Platform</span>
              <span className="Mobile_caret">
                <i className="fa fa-chevron-right" />
              </span>
              {/* start of drop menu */}
              <ul className="MobileMain_drop">
                {/* start of sub drop menu */}
                <li>
                  <Link to="/platform/rapflow">RAPFlow</Link>
                </li>
                <li>
                  <Link to="/platform/rapbot">RAPBot</Link>
                </li>
                {/* <li>How It Works</li> */}
              </ul>
              {/* end of drop menu */}
            </li>
            <li>
              <span className="mobile_menu_drop">Solution Focus</span>
              <span className="Mobile_caret">
                <i className="fa fa-chevron-right" />
              </span>
              <ul className="MobileMain_drop">
                <li>
                  <Link to="/applications/form-recognition">
                    Form Recognition and Processing
                  </Link>
                </li>
                <li>
                  <Link to="/applications/customer-service-automation">
                    Customer Service Automation
                  </Link>
                </li>
                {/* <li>
                  <Link to="/applications/check-recognition">
                    Check Recognition
                  </Link>
                </li>
                 <li>
                  <Link to="/applications/quote-processing">
                    Quote Processing
                  </Link>
                </li> 
                 <li>
                  <Link to="/applications/case-deflection">
                    Case Deflection
                  </Link>
                </li>
                <li>
                  <Link to="/applications/invoice-processing">
                    Invoice Processing
                  </Link>
                </li>
                <li>
                  <Link to="/applications/lease-abstraction">
                    Lease Abstraction
                  </Link>
                </li> */}
                <li>
                  <Link to="/applications/document-processing">
                    Document Understanding & Processing
                  </Link>
                </li>
                <li>
                  <Link to="/applications/intelligent-process-automation">
                    Intelligent Automation
                  </Link>
                </li>
                <li>
                  <Link to="/applications/build-your-own">Build Your Own</Link>
                </li>
              </ul>
            </li>
            <li>
              <span className="mobile_menu_drop">Use Cases</span>
              <span className="Mobile_caret">
                <i className="fa fa-chevron-right" />
              </span>
              <ul className="MobileMain_drop">
                <li className="MobileMainsub_drop">
                  <span className="mobile_menu_drop">Functions</span>
                  <span className="Mobile_caret">
                    <i className="fa fa-chevron-right" />
                  </span>
                  <ul className="subMenu_list">
                    <li>
                      <Link to="/solutions/it">IT</Link>
                    </li>
                    <li>
                      <Link to="/solutions/customer-service">
                        Customer Service
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/finance-procurement">
                        Finance/Procurement
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/operations">Operations</Link>
                    </li>
                  </ul>
                </li>
                <li className="MobileMainsub_drop">
                  <span className="mobile_menu_drop">Industry Solutions</span>
                  <span className="Mobile_caret">
                    <i className="fa fa-chevron-right" />
                  </span>
                  <ul className="subMenu_list">
                    <li>
                      <Link to="/solutions/bfsi">BFSI</Link>
                    </li>
                    <li>
                      <Link to="/solutions/logistics">Logistics</Link>
                    </li>
                    <li>
                      <Link to="/solutions/healthcare">Healthcare</Link>
                    </li>
                    <li>
                      <Link to="/solutions/real-estate">Real Estate</Link>
                    </li>
                    <li>
                      <Link to="/solutions/hospitality-management">
                        Hospitality Management
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/shared-services">
                        Shared Services
                      </Link>
                    </li>
                    <li>
                      <Link to="/solutions/cross-industry">Cross Industry</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            {/* <li>
              <a href="https://blog.rapidautomation.ai/">Blog</a>
            </li> */}
            <li>
              <span className="mobile_menu_drop">Resources</span>
              <span className="Mobile_caret">
                <i className="fa fa-chevron-right" />
              </span>
              <ul className="MobileMain_drop">
                <li>
                  <a href="https://blog.rapidautomation.ai/">Blog</a>
                </li>
                <li>
                  <Link to="/news">News</Link>
                </li>
                <li>
                  <Link to="/case-studies/">Case Studies</Link>
                </li>
                {/* <li>
                  <Link to="/infographics/">Infographics</Link>
                </li>
                <li>
                  <Link to="/miscellaneous">Miscellaneous</Link>
                </li> */}
                <li>
                  <Link to="/pocketresources">Pocket Resources</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>

            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li className="btn_mobile_holder">
              <Link to="/bookademo" className="btn btn_mobile">
                Book a Demo
              </Link>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

export default MainMobilemenu1;
