import React from 'react';
// import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import Button from 'common/src/components/Button';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Box from 'common/src/components/Box';

import CaseIcon from 'common/src/assets/image/hosting/Menu/Application/Case Deflection.png';
import CheckIcon from 'common/src/assets/image/hosting/Menu/Application/Check Recognition.png';
import FormIcon from 'common/src/assets/image/hosting/Menu/Application/Form Recognition.png';
import IDIcon from 'common/src/assets/image/hosting/Menu/Application/ID Verification.png';
import EmailIcon from 'common/src/assets/image/hosting/Menu/Application/Intelligent Email Response.png';
import InvoiceIcon from 'common/src/assets/image/hosting/Menu/Application/Invoice Processing.png';
import LeaseIcon from 'common/src/assets/image/hosting/Menu/Application/Lease Abstraction.png';
import QuoteIcon from 'common/src/assets/image/hosting/Menu/Application/Quote Processing.png';
import WorkflowIcon from 'common/src/assets/image/hosting/Menu/Application/Workflow Automation.png';
import RapFlowIcon from 'common/src/assets/image/hosting/Menu/RAPFlow.png';
import RapBotIcon from 'common/src/assets/image/hosting/Menu/RAPBot.png';

// import EyemagicIcon from 'common/src/assets/image/hosting/Menu/Platform/EyeMagic.png';
// import ResponsegenieIcon from 'common/src/assets/image/hosting/Menu/Platform/ResponseGenie.png';

import DesktopMenuWrapper from './MainDesktopMenu.style';

const Maindeskmenu1 = (button) => (
  <DesktopMenuWrapper>
    <nav id="desktopmenu">
      <ul className="flexboxprops">
        <li className="mainmenuhead position_relative">
          <span className="mclr">
            Platform <i className="fa fa-chevron-down pad_left_favi" />
          </span>
          <nav className="dropdownMenu dropdownMenu5">
            <ul className="">
              <li className="text_bold icon_menu">
                <Link
                  to="/platform/rapflow"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={RapFlowIcon} alt="rapflow" />
                  </span>
                  <span className="text text_bold">RAPFlow</span>
                  <span className="text text_size12 text_center">
                    Full Lifecycle AI Orchestration
                  </span>
                </Link>
              </li>
              <li className="text_bold icon_menu">
                <Link
                  to="/platform/rapbot"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={RapBotIcon} alt="RapBot" />
                  </span>
                  <span className="text text_bold">RAPBot</span>
                  <span className="text text_size12 text_center">
                    End-to-end automation with intelligent RPA
                  </span>
                </Link>
              </li>
            </ul>
            {/* <Box className="gtnStarted">
            <Link to="/platform/getting-started" className="btn btn_tertiary">
              <span className="btn-text">Getting Started</span>
            </Link>
          </Box> */}
          </nav>
        </li>
        <li className="mainmenuhead2">
          <span className="mclr">
            Solution Focus <i className="fa fa-chevron-down pad_left_favi" />
          </span>
          <nav className="dropdownMenu dropdownMenu2">
            <ul className="flexboxprops flexalignstart">
              {/* <li>
                <Link
                  to="/applications/form-recognition"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={FormIcon} alt="Form Recognition" />
                  </span>
                  <span className="text_bold">
                    Form
                    <br />
                    Processing
                  </span>
                  <span className="text_size12">
                    Automate and accelerate form recognition with greater
                    accuracy and productivity
                  </span>
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/applications/intelligent-email-response"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={EmailIcon} alt="Intelligent Email Response" />
                  </span>
                  <span className="text_bold">
                    Intelligent
                    <br />
                    Email Response
                  </span>
                  <span className="text_size12">
                    Expedite email response with entity and intent extraction
                    automated by the RAPFlow platform
                  </span>
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/applications/check-recognition"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={CheckIcon} alt="Check Recognition" />
                  </span>
                  <span className="text_bold">
                    Check
                    <br />
                    Recognition
                  </span>
                  <span className="text_size12">
                    Accelerate check processing with AI-powered automation of
                    check recognition tasks
                  </span>
                </Link>
              </li> */}
              {/* <li>
                <Link
                  to="/applications/case-deflection"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={CaseIcon} alt="Case Deflection" />
                  </span>
                  <span className="text_bold">
                    Case
                    <br />
                    Deflection
                  </span>
                  <span className="text_size12">
                    Minimize case deflection with rapid, accurate responses
                    through AI automation
                  </span>
                </Link>
              </li> */}
            </ul>
            <ul className="flexboxprops flexalignstart">
              <li>
                <Link
                  to="/applications/form-recognition"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={FormIcon} alt="Form Recognition" />
                  </span>
                  <span className="text_bold">
                    Form Recognition
                    <br />& Processing
                  </span>
                  <span className="text_size12">
                    Automate and accelerate form recognition with greater
                    accuracy and productivity
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/applications/customer-service-automation"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={InvoiceIcon} alt="Customer Service" />
                  </span>
                  <span className="text_bold">
                    Customer Service
                    <br />
                    Automation
                  </span>
                  <span className="text_size12">
                    Accurate, efficient and speedy invoice processing through
                    AI-powered automation
                  </span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/applications/lease-abstraction"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={LeaseIcon} alt="Lease Abstraction" />
                  </span>
                  <span className="text_bold">
                    Lease
                    <br />
                    Abstraction
                  </span>
                  <span className="text_size12">
                    Rapid lease abstraction processes with high accuracy due to
                    AI automation
                  </span>
                </Link>
              </li> */}
              <li>
                <Link
                  to="/applications/document-processing"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={IDIcon} alt="ID Verification" />
                  </span>
                  <span className="text_bold">
                    Document Understanding
                    <br />& Processing
                  </span>
                  <span className="text_size12">
                    Take the grunt work from document processing using the
                    RAPFlow platform
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/applications/intelligent-process-automation"
                  className="flexboxprops flexcolumnprops"
                >
                  <span>
                    <img src={WorkflowIcon} alt="Workflow Automation" />
                  </span>
                  <span className="text_bold">
                    Intelligent
                    <br />
                    Automation
                  </span>
                  <span className="text_size12">
                    Achieve peak efficiencies and scale with Intelligent Process
                    Automation
                  </span>
                </Link>
              </li>
            </ul>
            <div>
              <Box className="buildYourOwn">
                <Link
                  to="/applications/build-your-own"
                  className="btn btn_tertiary"
                >
                  <span className="btn-text">Build Your Own</span>
                </Link>
              </Box>
            </div>
          </nav>
        </li>
        <li className="mainmenuhead">
          <span className="mclr">
            Use Cases
            <i className="fa fa-chevron-down pad_left_favi" />
          </span>
          <nav className="dropdownMenu dropdownMenu3 flexboxprops flexjustifyaround">
            <ul>
              <li className="text_bold spacingmenu">By Functions</li>
              <li>
                <Link to="/solutions/it">IT</Link>
              </li>
              <li>
                <Link to="/solutions/customer-service">Customer Service</Link>
              </li>
              <li>
                <Link to="/solutions/finance-procurement">
                  Finance/Procurement
                </Link>
              </li>
              <li>
                <Link to="/solutions/operations">Operations</Link>
              </li>
            </ul>
            <ul>
              <li className="text_bold spacingmenu">By Industry</li>
              <li>
                <Link to="/solutions/bfsi">BFSI</Link>
              </li>
              <li>
                <Link to="/solutions/logistics">Logistics</Link>
              </li>
              <li>
                <Link to="/solutions/healthcare">Healthcare</Link>
              </li>
              <li>
                <Link to="/solutions/real-estate">Real Estate</Link>
              </li>
              <li>
                <Link to="/solutions/hospitality-management">
                  Hospitality Management
                </Link>
              </li>
              <li>
                <Link to="/solutions/shared-services">Shared Services</Link>
              </li>
              <li>
                <Link to="/solutions/cross-industry">Cross Industry</Link>
              </li>
            </ul>
          </nav>
        </li>
        <li className="mainmenuhead mainmenuheadAbout">
          <Link to="/about" className="customItem mclr">
            About
          </Link>
        </li>
        {/* <li className="mainmenuhead menu_blog">
        <a href="https://blog.rapidautomation.ai/">Blog</a>
      </li> */}
        <li className="mainmenuhead position_relative">
          <span className="mclr">
            Resources <i className="fa fa-chevron-down pad_left_favi" />
          </span>
          <nav className="dropdownMenu dropdownMenu4">
            <ul className="">
              <li className="text_bold">
                <a href="https://blog.rapidautomation.ai/">Blog</a>
              </li>
              <li className="text_bold">
                <Link to="/news">News</Link>
              </li>
              <li className="text_bold">
                <Link to="/case-studies/">Case Studies</Link>
              </li>
              {/* <li className="text_bold">
                <Link to="/infographics">Infographics</Link>
              </li>
              <li className="text_bold">
                <Link to="/miscellaneous">Miscellaneous</Link>
              </li> */}
              <li className="text_bold">
                <Link to="/pocketresources">Pocket Resources</Link>
              </li>
            </ul>
          </nav>
        </li>

        <li>
          <Link to="/bookademo" className="btn btn_tertiary">
            <span className="btn-text bookd">Book a Demo</span>
          </Link>
        </li>
      </ul>
    </nav>
  </DesktopMenuWrapper>
);

Maindeskmenu1.propTypes = {
  button: PropTypes.object,
};
Maindeskmenu1.defaultProps = {
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
};
export default Maindeskmenu1;
