import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Box from "common/src/components/Box";
import Text from "common/src/components/Text";
import Heading from "common/src/components/Heading";
import Logo from "common/src/components/UIElements/Logo";
import Container from "common/src/components/UI/Container";
import FooterWrapper, { List, ListItem } from "./footer.style";
import Achievementimage from "common/src/assets/image/hosting/news/footer_analytics.jpg";
import Achievementimage2 from "common/src/assets/image/hosting/news/footer_cioreview.jpg";
import axios from "axios";
import { Link } from "gatsby";

import scrollTo from "gatsby-plugin-smoothscroll";

import LogoImage from "common/src/assets/image/hosting/logo.svg";

const Footer = ({
  row,
  col,
  colOne,
  colTwo,
  colThree,
  colFour,
  titleStyle,
  logoStyle,
  textStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      hostingJson {
        FOOTER_WIDGET {
          title
          menuItems {
            text
            url
          }
        }
      }
    }
  `);

  function cokkieBtn() {
    document.cookie = "username=RapUser; path=/;";
    document.getElementById("___gatsby").classList.remove("cookie_msg_show");
  }
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    axios
      .get("https://blog.rapidautomation.ai/wp-json/wp/v2/posts")
      .then((Response) => {
        Response.data.forEach((x) => {
          setPosts((datas) => [
            ...datas,
            { title: x.title.rendered, link: x.link },
          ]);
        });
      })
      // .then(console.log(posts));
  }, []);

  return (
    <FooterWrapper>
      <Container className="footer_container">
        <Box className="row" {...row}>
          <Box {...colOne}>
            <Logo
              href="/"
              logoSrc={LogoImage}
              title="Rapid Acceleration Partners"
              className="footer_logo"
              logoStyle={logoStyle}
            />
            <Text
              content="Rapid Acceleration Partners provides practical AI solutions for digital business transformation. With a view to democratize AI, RAP has developed products RAPFlow - an AI orchestration platform for building content intelligence solutions, and RAPBot - our RPA tool for end-to-end automation."
              {...textStyle}
            />
            <Box className="socialmedia_icons">
              <a href="https://www.linkedin.com/company/rapid-acceleration-partners">
                <i className="fa fa-linkedin-square" />
              </a>
              <a href="https://www.facebook.com/rapventures/">
                <i className="fa fa-facebook-square" />
              </a>
              <a href="https://twitter.com/rap_ventures">
                <i className="fa fa-twitter-square" />
              </a>
              <a href="https://www.instagram.com/rapid_acceleration_partners/">
                <i className="fa fa-instagram" />
              </a>
              <a href="https://www.youtube.com/channel/UCMSXhWJFm3Vvp1Rmbfa54lw/">
                <i className="fa fa-youtube-square" />
              </a>
            </Box>
          </Box>
          {/* End of footer logo column */}
          <Box {...colTwo} className="colTwo flexjustifycenter">
            {Data.hostingJson.FOOTER_WIDGET.map((widget, index) => (
              <Box key={`footer-widget-${index}`}>
                <Heading content={widget.title} {...titleStyle} />
                <List>
                  {widget.menuItems.map((item, index) => (
                    <ListItem key={`footer-list-item-${index}`}>
                      <a href={item.url} className="ListItem">
                        {item.text}
                      </a>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Box>
          {/* End of footer List column */}
          <Box {...colThree}>
            <Box>
              <Heading content="Recent Posts" {...titleStyle} />
              <List>
                {posts.slice(0, 3).map((x, i) => (
                  <ListItem key={i}>
                    <a href={x.link} className="ListItem">
                      {x.title}
                    </a>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
          <Box {...colFour}>
            <Box>
              <Heading content="Awards" {...titleStyle} />
              <Box>
                <Link to="/news/analytics-insights-magazine">
                  <img
                    src={Achievementimage}
                    style={{ width: "100%", maxWidth: "100px" }}
                    alt="RAPs'achievement certificate in Analytics insight magazine"
                  />
                </Link>
              </Box>
              <Box>
                <Link to="/news/cio-review">
                  <img
                    src={Achievementimage2}
                    style={{
                      width: "100%",
                      maxWidth: "100px",
                      marginTop: "20px",
                    }}
                    alt="RAPs'achievement certificate in CIO Review magazine"
                  />
                </Link>
              </Box>
            </Box>
          </Box>
          {/* End of footer List column */}
        </Box>
        <Box className="copyright_container">
          <p className="text_center">
            Copyright &copy; {new Date().getFullYear()} Rapid Acceleration
            Partners, Inc.
          </p>
        </Box>
      </Container>
      <Box id="scrollTopBtn" onClick={() => scrollTo("#___gatsby")}>
        <i className="fa fa-chevron-up" />
      </Box>
      <Box id="Cookie_msg">
        <div>
          We use cookies to ensure that we give you the best experience on our
          website. If you continue to use this site we will assume that you are
          happy with it.
          <span className="cookie_btn" onClick={() => cokkieBtn()}>
            OK
          </span>
        </div>
      </Box>
    </FooterWrapper>
  );
};

// Footer style props
Footer.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  colOne: PropTypes.object,
  colTwo: PropTypes.object,
  colThree: PropTypes.object,
  titleStyle: PropTypes.object,
  textStyle: PropTypes.object,
  logoStyle: PropTypes.object,
};

Footer.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    ml: "-15px",
    mr: "-15px",
  },
  colOne: {
    width: [1, "30%", "30%", "30%"],
    // width: [1, '33%', '33%', '33%'],
    mt: [0, "0px"],
    mb: ["30px", 0],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  colTwo: {
    className: "colTwo",
    width: [1, "20%", "20%", "20%"],
    // width: [1, '33%', '33%', '33%'],
    flexBox: true,
    flexWrap: "wrap",
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  colThree: {
    width: [1, "30%", "30%", "30%"],
    // width: [1, '33%', '33%', '33%'],
    pl: ["15px", 0],
    pr: "15px",
  },
  colFour: {
    width: [1, "20%", "20%", "20%"],
    pl: ["15px", 0],
    pr: ["15px", "15px", 0],
  },
  col: {
    width: ["100%", "50%", "50%", "25%"],
    pl: "15px",
    pr: "15px",
    mb: "30px",
  },
  titleStyle: {
    as: "h3",
    color: "#343d48",
    fontSize: "16px",
    fontWeight: "700",
    mb: "10px",
  },
  logoStyle: {
    width: "130px",
    mb: "15px",
  },
  textStyle: {
    color: "#0f2137",
    fontSize: "16px",
    mb: "10px",
  },
};

export default Footer;
