import React from 'react';
// import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import Button from 'common/src/components/Button';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Box from 'common/src/components/Box';

import CaseIcon from 'common/src/assets/image/hosting/Menu/Application/Case Deflection.png';
import CheckIcon from 'common/src/assets/image/hosting/Menu/Application/Check Recognition.png';
import FormIcon from 'common/src/assets/image/hosting/Menu/Application/Form Recognition.png';
import IDIcon from 'common/src/assets/image/hosting/Menu/Application/ID Verification.png';
import EmailIcon from 'common/src/assets/image/hosting/Menu/Application/Intelligent Email Response.png';
import InvoiceIcon from 'common/src/assets/image/hosting/Menu/Application/Invoice Processing.png';
import LeaseIcon from 'common/src/assets/image/hosting/Menu/Application/Lease Abstraction.png';
import QuoteIcon from 'common/src/assets/image/hosting/Menu/Application/Quote Processing.png';
import WorkflowIcon from 'common/src/assets/image/hosting/Menu/Application/Workflow Automation.png';
import RapFlowIcon from 'common/src/assets/image/hosting/Menu/RAPFlow.png';
import RapBotIcon from 'common/src/assets/image/hosting/Menu/RAPBot.png';

// import EyemagicIcon from 'common/src/assets/image/hosting/Menu/Platform/EyeMagic.png';
// import ResponsegenieIcon from 'common/src/assets/image/hosting/Menu/Platform/ResponseGenie.png';

import DesktopMenuWrapper from './newdesk.style';

const Maindeskmenu1 = button => (
  <DesktopMenuWrapper>
    <nav>
      <ul className="desktop_dropdown_menu">
        {/* -----------------------------Platfrom menu------------------------------ */}
        <li className="navitems">
          <span>
            Platform
            <i className="fa fa-chevron-down" />
          </span>
          <ul className="dropdownmenu platform_menu_size">
            <li className="dropdownmenu_toggler">
              <span>RAPBot</span>
              <ul className="submenu visiblemenu display_flex">
                <li>
                  <Link to="/platform/rapbot" className="display_flex">
                    <span>
                      <img src={RapBotIcon} alt="RapBot" />
                    </span>
                    <span>RAPBot</span>
                    <span className="centered_text">
                      End-to-end automation with intelligent RPA
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="dropdownmenu_toggler">
              <span>RAPFlow</span>
              <ul className="submenu display_flex">
                <li>
                  <Link to="/platform/rapflow" className="display_flex">
                    <span>
                      <img src={RapFlowIcon} alt="rapflow" />
                    </span>
                    <span>RAPFlow</span>
                    <span className="centered_text">
                      Full Lifecycle AI Orchestration
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        {/* ------------------------Solution Focus menu ----------------------- */}
        <li className="navitems">
          <span>
            Solution Focus
            <i className="fa fa-chevron-down" />
          </span>
          <ul className="dropdownmenu solutionfocus_menu_size">
            <li className="dropdownmenu_toggler">
              <span>
                Form <br />
                Recognition
                <br /> & Processing
              </span>
              <ul className="submenu visiblemenu display_flex">
                <li>
                  <Link
                    to="/applications/form-recognition"
                    className="display_flex"
                  >
                    <span>
                      <img src={FormIcon} alt="Form Recognition" />
                    </span>
                    <span className="centered_text">
                      Form Recognition & Processing
                    </span>
                    <span className="centered_text smallfont">
                      Automate and accelerate form recognition with greater
                      accuracy and productivity
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="dropdownmenu_toggler">
              <span className="centered_text">
                Customer <br /> Service <br /> Automation
              </span>
              <ul className="submenu display_flex">
                <li>
                  <Link
                    to="/applications/customer-service-automation"
                    className="display_flex"
                  >
                    <span>
                      <img src={InvoiceIcon} alt="rapflow" />
                    </span>
                    <span>Customer Service Automation</span>
                    <span className="centered_text smallfont">
                      Accurate, efficient and speedy invoice processing through
                      AI-powered automation
                    </span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="dropdownmenu_toggler">
              <span className="centered_text">
                Document <br />
                Understanding
                <br /> & Processing
              </span>
              <ul className="submenu display_flex">
                <li>
                  <Link
                    to="/applications/document-processing"
                    className="display_flex"
                  >
                    <span>
                      <img src={IDIcon} alt="IDIcon" />
                    </span>
                    <span className="centered_text">
                      Document Understanding & Processing
                    </span>
                    <span className="centered_text smallfont">
                      Take the grunt work from document processing using the
                      RAPFlow platform
                    </span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="dropdownmenu_toggler">
              <span className="centered_text">
                Intelligent
                <br /> Automation
              </span>
              <ul className="submenu display_flex">
                <li>
                  <Link
                    to="/applications/intelligent-process-automation"
                    className="display_flex"
                  >
                    <span>
                      <img src={WorkflowIcon} alt="WorkflowIcon" />
                    </span>
                    <span className="centered_text">
                      Intelligent Automation
                    </span>
                    <span className="centered_text smallfont">
                      Achieve peak efficiencies and scale with Intelligent
                      Process Automation
                    </span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="dropdownmenu_toggler left_margin_button">
              {/* <span className="centered_text "> */}
              <Link
                to="/applications/build-your-own"
                className="btn btn_tertiary "
              >
                <span className="btn-text">Build Your Own</span>
              </Link>
              {/* </span> */}
            </li>
          </ul>
        </li>
        {/* -------------------------------Use Cases menu------------------------------- */}

        <li className="navitems">
          <span>
            Use Cases
            <i className="fa fa-chevron-down" />
          </span>
          <ul className="dropdownmenu usecasess_menu_size">
            <li className="dropdownmenu_toggler">
              <span>By Functions</span>
              <ul className="submenu visiblemenu padded_lists ">
                <li>
                  <Link to="/solutions/it">IT</Link>
                </li>
                <li>
                  <Link to="/solutions/customer-service">Customer Service</Link>
                </li>
                <li>
                  <Link to="/solutions/finance-procurement">
                    Finance/Procurement
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/operations">Operations</Link>
                </li>
              </ul>
            </li>
            <li className="dropdownmenu_toggler">
              <span>By Industry</span>
              <ul className="submenu padded_lists">
                <li>
                  <Link to="/solutions/bfsi">BFSI</Link>
                </li>
                <li>
                  <Link to="/solutions/logistics">Logistics</Link>
                </li>
                <li>
                  <Link to="/solutions/healthcare">Healthcare</Link>
                </li>
                <li>
                  <Link to="/solutions/real-estate">Real Estate</Link>
                </li>
                <li>
                  <Link to="/solutions/hospitality-management">
                    Hospitality Management
                  </Link>
                </li>
                <li>
                  <Link to="/solutions/shared-services">Shared Services</Link>
                </li>
                <li>
                  <Link to="/solutions/cross-industry">Cross Industry</Link>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        {/* ------------------------------------About menu (no dropdowns) -----------------------------*/}
        <li className="navitems">
          <Link to="/about" className="customItem">
            About
          </Link>
        </li>
        {/* ------------------------------------ Resources-------------------------------------------- */}
        <li className="navitems">
          <span>
            Resources
            <i className="fa fa-chevron-down" />
          </span>
          <ul className="dropdownmenu resources_menu_size padded_lists">
            <li className="dropdownmenu_toggler">
              <a href="https://blog.rapidautomation.ai/">Blog</a>
            </li>
            {/* <li className="dropdownmenu_toggler">
              <Link to="/news">News</Link>
            </li> */}
            <li className="dropdownmenu_toggler">
              <Link to="/case-studies/">Case Studies</Link>
            </li>
            <li className="dropdownmenu_toggler">
              <Link to="/pocketresources">Pocket Resources</Link>
            </li>
          </ul>
        </li>
        {/* ----------------------------------------------button-------------------------------------- */}
        <li>
          <Link to="/bookademo" className="btn btn_tertiary">
            <span className="btn-text">Book a Demo</span>
          </Link>
        </li>
      </ul>
    </nav>
  </DesktopMenuWrapper>
);

Maindeskmenu1.propTypes = {
  button: PropTypes.object,
};
Maindeskmenu1.defaultProps = {
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'primaryWithBg',
    minHeight: 'auto',
    height: `${1}`,
  },
};
export default Maindeskmenu1;
